.grid-setup {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 10px;
  display: grid;
}

@media (width >= 1180px) {
  .grid-setup {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 20px;
  }
}

@font-face {
  font-family: GT Pantheon;
  src: url("GT-Pantheon-VF.ee7eeb4e.woff2") format("woff2-variations");
  font-weight: normal;
}

@font-face {
  font-family: GT Pantheon Italic;
  src: url("GT-Pantheon-Italic-VF.daa3e98b.woff2") format("woff2-variations");
  font-weight: normal;
  font-style: normal;
}

:root {
  --upright-fonts: "GT Pantheon";
  --italic-fonts: "GT Pantheon Italic";
}

.italic-font {
  font-family: var(--italic-fonts);
  font-style: normal;
}

.font-micro-light {
  --font-wght: var(--wght-light);
  --font-opsz: var(--opsz-micro);
}

.font-micro-regular {
  --font-wght: var(--wght-regular);
  --font-opsz: var(--opsz-micro);
}

.font-micro-medium {
  --font-wght: var(--wght-medium);
  --font-opsz: var(--opsz-micro);
}

.font-micro-bold {
  --font-wght: var(--wght-bold);
  --font-opsz: var(--opsz-micro);
}

.font-micro-black {
  --font-wght: var(--wght-black);
  --font-opsz: var(--opsz-micro);
}

.font-text-light {
  --font-wght: var(--wght-light);
  --font-opsz: var(--opsz-text);
}

.font-text-regular {
  --font-wght: var(--wght-regular);
  --font-opsz: var(--opsz-text);
}

.font-text-medium {
  --font-wght: var(--wght-medium);
  --font-opsz: var(--opsz-text);
}

.font-text-bold {
  --font-wght: var(--wght-bold);
  --font-opsz: var(--opsz-text);
}

.font-text-black {
  --font-wght: var(--wght-black);
  --font-opsz: var(--opsz-text);
}

.font-display-light {
  --font-wght: var(--wght-light);
  --font-opsz: var(--opsz-display);
}

.font-display-regular {
  --font-wght: var(--wght-regular);
  --font-opsz: var(--opsz-display);
}

.font-display-medium {
  --font-wght: var(--wght-medium);
  --font-opsz: var(--opsz-display);
}

.font-display-bold {
  --font-wght: var(--wght-bold);
  --font-opsz: var(--opsz-display);
}

.font-display-black {
  --font-wght: var(--wght-black);
  --font-opsz: var(--opsz-display);
}

.font-ital-micro-light {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-light);
  --font-opsz: var(--opsz-micro);
}

.font-ital-micro-regular {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-regular);
  --font-opsz: var(--opsz-micro);
}

.font-ital-micro-medium {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-medium);
  --font-opsz: var(--opsz-micro);
}

.font-ital-micro-bold {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-bold);
  --font-opsz: var(--opsz-micro);
}

.font-ital-micro-black {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-black);
  --font-opsz: var(--opsz-micro);
}

.font-ital-text-light {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-light);
  --font-opsz: var(--opsz-text);
}

.font-ital-text-regular {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-regular);
  --font-opsz: var(--opsz-text);
}

.font-ital-text-medium {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-medium);
  --font-opsz: var(--opsz-text);
}

.font-ital-text-bold {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-bold);
  --font-opsz: var(--opsz-text);
}

.font-ital-text-black {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-black);
  --font-opsz: var(--opsz-text);
}

.font-ital-display-light {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-light);
  --font-opsz: var(--opsz-display);
}

.font-ital-display-regular {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-regular);
  --font-opsz: var(--opsz-display);
}

.font-ital-display-medium {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-medium);
  --font-opsz: var(--opsz-display);
}

.font-ital-display-bold {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-bold);
  --font-opsz: var(--opsz-display);
}

.font-ital-display-black {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-black);
  --font-opsz: var(--opsz-display);
}

nav a:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.small-caps {
  font-feature-settings: "smcp" 1, "kern" 1;
  line-height: 1em;
}

.all-caps, .caps {
  text-transform: uppercase;
  line-height: 1em;
}

.text-xs.all-caps, .text-xs.small-caps {
  letter-spacing: .03em;
}

.ot-onum {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "pnum" 1;
  font-feature-settings: "kern" 1, "onum" 1, "pnum" 1;
}

.ot-onum-tnum {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "tnum" 1;
  font-feature-settings: "kern" 1, "onum" 1, "tnum" 1;
}

.ot-lnum {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "pnum" 1;
  font-feature-settings: "kern" 1, "lnum" 1, "pnum" 1;
}

.ot-lnum-tnum {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "tnum" 1;
  font-feature-settings: "kern" 1, "lnum" 1, "tnum" 1;
}

.ot-dlig {
  -webkit-font-feature-settings: "kern" 1, "liga" 1, "dlig" 1;
  font-feature-settings: "kern" 1, "liga" 1, "dlig" 1;
}

.ot-sups {
  text-transform: lowercase;
  -webkit-font-feature-settings: "kern" 1, "sups" 1;
  font-feature-settings: "kern" 1, "sups" 1;
}

.ot-onum-slash {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "onum" 1, "zero" 1;
}

.ot-lnum-slash {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "lnum" 1, "zero" 1;
}

.ot-tf-onum-slash {
  -webkit-font-feature-settings: "kern" 1, "tnum" 1, "onum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "tnum" 1, "onum" 1, "zero" 1;
}

.ot-tf-lnum-slash {
  -webkit-font-feature-settings: "kern" 1, "tnum" 1, "lnum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "tnum" 1, "lnum" 1, "zero" 1;
}

.ot-osf {
  -webkit-font-feature-settings: "onum" 1, "kern" 1;
  font-feature-settings: "onum" 1, "kern" 1;
}

.ot-osf.small-caps {
  -webkit-font-feature-settings: "smcp" 1, "kern" 1, "onum" 1, "pnum" 1;
  font-feature-settings: "smcp" 1, "kern" 1, "onum" 1, "pnum" 1;
}

.ot-sc-osf {
  font-feature-settings: "smcp" 1, "onum" 1, "kern" 1;
  -webkit-font-feature-settings: "smcp" 1, "onum" 1, "kern" 1;
}

.ot-ssplusarrow {
  -webkit-font-feature-settings: "kern" 1, "ss01" 1, "ss02" 1, "ss03" 1, "ss04" 1, "ss05" 1;
  font-feature-settings: "kern" 1, "ss01" 1, "ss02" 1, "ss03" 1, "ss04" 1, "ss05" 1;
}

.ot-salt {
  -webkit-font-feature-settings: "calt" 1, "kern" 1;
  font-feature-settings: "calt" 1, "kern" 1;
}

.ot-smcp {
  -webkit-font-feature-settings: "smcp" 1, "kern" 1, "c2sc" 1;
  font-feature-settings: "smcp" 1, "kern" 1, "c2sc" 1;
}

.ot-case {
  -webkit-font-feature-settings: "case" 1, "kern" 1;
  font-feature-settings: "case" 1, "kern" 1;
}

.ot-ss01 {
  -webkit-font-feature-settings: "ss01" 1, "kern" 1;
  font-feature-settings: "ss01" 1, "kern" 1;
}

.ot-ss05 {
  -webkit-font-feature-settings: "ss05" 1, "kern" 1;
  font-feature-settings: "ss05" 1, "kern" 1;
}

.ot-aalt {
  -webkit-font-feature-settings: "aalt" 1, "kern" 1;
  font-feature-settings: "aalt" 1, "kern" 1;
}

.hover-circular-dots:hover {
  -webkit-font-feature-settings: "ss05" 1, "kern" 1;
  font-feature-settings: "ss05" 1, "kern" 1;
}

.js-animation-paused, .js-animation-paused * {
  animation-play-state: paused !important;
}

.animation-lottie {
  position: relative;
}

.is-init #loader-section {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.h1-reveal h1 {
  color: #0000;
  background: linear-gradient(90deg, #c8a168 0%, #0000 90% 100%) 100% 0 / 500% 100% no-repeat text;
  transition: background-position-x .2s ease-out;
}

.h1-reveal:hover h1 {
  background-position-x: 0%;
  transition: background-position-x .2s ease-in;
}

.anim-design-family-overview {
  animation-name: animation-design-family-overview;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes animation-design-family-overview {
  0% {
    font-variation-settings: "opsz" 10, "wght" var(--font-wght);
  }

  50% {
    font-variation-settings: "opsz" 17, "wght" var(--font-wght);
  }

  100% {
    font-variation-settings: "opsz" 32, "wght" var(--font-wght);
  }
}

.morph-station {
  color: #0000;
  background-image: linear-gradient(90deg, #645134 0% 30%, #c8a168 42% 58%, #645134 70% 100%);
  background-position-x: 0%;
  background-size: 200% 100%;
  background-clip: text;
  animation-name: morph-stations--pingpong--3-1;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes morph-stations--pingpong--3-1 {
  from {
    background-position-x: 100%;
  }

  to {
    background-position-x: 0%;
  }
}

@keyframes morph-stations--pingpong--3-2 {
  0% {
    background-position-x: 100%;
  }

  20% {
    background-position-x: 100%;
  }

  30% {
    background-position-x: 50%;
  }

  70% {
    background-position-x: 50%;
  }

  80% {
    background-position-x: 0%;
  }

  100% {
    background-position-x: 0%;
  }
}

@keyframes morph-stations--pingpong--3-3 {
  0% {
    background-position-x: 100%;
  }

  70% {
    background-position-x: 100%;
  }

  80% {
    background-position-x: 50%;
  }

  100% {
    background-position-x: 50%;
  }
}

.anim-charset-glyph {
  animation: 3s ease-in-out infinite alternate animation-charset-glyph;
}

@keyframes animation-charset-glyph {
  0% {
    font-variation-settings: "wght" 300, "opsz" 32;
  }

  33% {
    font-variation-settings: "wght" 300, "opsz" 10;
  }

  66% {
    font-variation-settings: "wght" 900, "opsz" 10;
  }

  100% {
    font-variation-settings: "wght" 900, "opsz" 32;
  }
}

.anim-divider-center {
  animation-name: animation-divider-center;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

@keyframes animation-divider-center {
  0% {
    transform: rotate(0);
  }

  35% {
    transform: rotate(0);
  }

  40% {
    transform: rotate(45deg);
  }

  60% {
    transform: rotate(45deg);
  }

  65% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(90deg);
  }
}

.anim-divider-outermost {
  animation-name: animation-divider-outermost;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes animation-divider-outermost {
  0% {
    flex-basis: 10%;
  }

  20% {
    flex-basis: 10%;
  }

  25% {
    flex-basis: 20%;
  }

  45% {
    flex-basis: 20%;
  }

  50% {
    flex-basis: 10%;
  }

  100% {
    flex-basis: 10%;
  }
}

.sticky-nav-item {
  height: var(--height);
  color: #000;
}

.sticky-nav-item > button {
  top: var(--inner-top);
}

[data-component="sticky-subnav"] {
  top: var(--top);
}

[data-component="mobile-scrolly-header"] {
  will-change: transform;
  transform: translateY(var(--top));
}

.single-item {
  aspect-ratio: 1;
  letter-spacing: .02em;
  justify-content: center;
  align-items: center;
  width: calc(10% - .05px);
  font-size: 18px;
  line-height: 150%;
  display: flex;
}

@media (width >= 1180px) {
  .single-item {
    letter-spacing: .02em;
    width: calc(4.16667% - .05px);
    font-size: 22px;
    line-height: 125%;
  }
}

@media (width >= 1440px) {
  .single-item {
    width: calc(3.125% - .05px);
  }
}

.single-item {
  --font-opsz: var(--opsz-micro);
  box-shadow: 1px 0 silver, 0 1px silver, 1px 1px silver, inset 1px 0 silver, inset 0 1px silver;
}

.single-item:hover {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(200 161 104 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.charset-highlight.zoomed {
  pointer-events: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(200 161 104 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(200 161 104 / var(--tw-text-opacity));
  width: 200px;
  height: 200px;
  font-size: 31vw;
  line-height: 1.575;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (width >= 1180px) {
  .charset-highlight.zoomed {
    width: 300px;
    height: 300px;
    font-size: 22vw;
    line-height: 1.575;
  }
}

@media (width >= 1440px) {
  .charset-highlight.zoomed {
    width: 400px;
    height: 400px;
    font-size: 15vw;
    line-height: 1.5;
  }
}

[data-component="char-set__unicode"]:before {
  content: var(--unicode);
}

[data-component="char-set__script"]:before {
  content: var(--script);
}

[data-component="char-set__subset"]:before {
  content: var(--subset);
}

[data-component="char-set__subset-size"]:before {
  content: var(--subset-size);
}

[data-component="char-set__loupe"]:before {
  content: var(--char);
  font-feature-settings: var(--font-feature-settings);
}

@media (width >= 830px) {
  .container {
    max-width: 830px;
  }
}

@media (width >= 1180px) {
  .container {
    max-width: 1180px;
  }
}

@media (width >= 1440px) {
  .container {
    max-width: 1440px;
  }
}

@media (width >= 1720px) {
  .container {
    max-width: 1720px;
  }
}

@media (width >= 2000px) {
  .container {
    max-width: 2000px;
  }
}

body:not(.tt-active) .tt, body:not(.tt-active) button[data-component="tt-close"] {
  --tw-translate-y: -100vh;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: transform;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tt {
  transition: transform 1s;
  overflow: hidden;
}

body.tt-active .tt {
  pointer-events: auto;
  --tw-translate-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: transform;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

[data-component="tt__sample-text"] span {
  line-height: 100%;
}

@media (width >= 1180px) {
  [data-component="tt__sample-text"] span {
    line-height: 95%;
  }
}

.tt-sample {
  pointer-events: none;
  font-size: 40vw;
  line-height: 100%;
}

@media (width >= 1180px) {
  .tt-sample {
    font-size: 16vw;
    line-height: 100%;
  }
}

.tt-controls-container {
  --font-opsz: var(--opsz-micro);
  flex-direction: column;
  padding: 8px;
  display: flex;
}

@media (width >= 1180px) {
  .tt-controls-container {
    flex-direction: row;
    flex-grow: 1;
    width: 50vw;
    min-height: 80px;
  }
}

.tt-element-container {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  min-height: 45px;
  padding: 10px;
  display: flex;
}

@media (width >= 1180px) {
  .tt-element-container {
    min-height: 0;
    padding: 10px;
  }
}

.typetester-slider-container {
  flex-grow: 1;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.typetester-slider-label {
  text-align: left;
  width: 25%;
  padding-right: 10px;
}

@media (width >= 1180px) {
  .typetester-slider-label {
    width: 100%;
  }
}

.typetester-slider {
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
  outline-offset: 2px;
  outline: 2px solid #0000;
  flex-grow: 1;
  height: 1px;
  display: flex;
}

.typetester-slider:hover {
  cursor: pointer;
}

.typetester-slider::-webkit-slider-thumb {
  cursor: pointer;
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
  border-radius: 9999px;
  width: 15px;
  height: 15px;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.typetester-slider::-webkit-slider-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.typetester-slider::-moz-range-thumb {
  cursor: pointer;
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
  border-radius: 9999px;
  width: 15px;
  height: 15px;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.typetester-slider::-moz-range-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

[data-component="tt__wdth-value"], [data-component="tt__wght-value"], [data-component="tt__opsz-value"] {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  width: 45px;
  display: flex;
}

@media (width >= 1180px) {
  [data-component="tt__wdth-value"], [data-component="tt__wght-value"], [data-component="tt__opsz-value"] {
    justify-content: flex-start;
    width: 100%;
  }
}

[data-component="tt__opsz-value"]:after {
  content: var(--font-opsz-text);
}

[data-component="tt__wght-value"]:after {
  content: var(--font-wght-text);
}

[data-component="tt__sample"] [data-component="tt__sample-text"] {
  font-variation-settings: "wght" var(--font-wght), "opsz" var(--font-opsz);
}

[data-component="tt__settings-btn"][data-active="true"] .typetester-settings-toggle-indicator {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.two-axes-slider-thumb-rectangle::-webkit-slider-thumb {
  background: #000;
  border: 1px solid #c8a168;
  border-radius: 0%;
}

.two-axes-slider-thumb-rectangle::-moz-range-thumb {
  background: #000;
  border: 1px solid #c8a168;
  border-radius: 0%;
}

@media (width >= 1180px) {
  .two-axes-slider-thumb-rectangle::-webkit-slider-thumb {
    border-width: 2px;
  }

  .two-axes-slider-thumb-rectangle::-moz-range-thumb {
    border-width: 2px;
  }
}

.two-axes-slider-thumb-round::-webkit-slider-thumb {
  background: #000;
  border: 1px solid #c8a168;
  border-radius: 50%;
}

.two-axes-slider-thumb-round::-moz-range-thumb {
  background: #000;
  border: 1px solid #c8a168;
  border-radius: 50%;
}

@media (width >= 1180px) {
  .two-axes-slider-thumb-round::-webkit-slider-thumb {
    border-width: 2px;
  }

  .two-axes-slider-thumb-round::-moz-range-thumb {
    border-width: 2px;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 830px) {
  .container {
    max-width: 830px;
  }
}

@media (width >= 1180px) {
  .container {
    max-width: 1180px;
  }
}

@media (width >= 1440px) {
  .container {
    max-width: 1440px;
  }
}

@media (width >= 1720px) {
  .container {
    max-width: 1720px;
  }
}

@media (width >= 2000px) {
  .container {
    max-width: 2000px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.-left-\[2px\] {
  left: -2px;
}

.-right-\[2px\] {
  right: -2px;
}

.-top-1, .-top-\[1px\] {
  top: -1px;
}

.-top-\[2px\] {
  top: -2px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-3 {
  bottom: 4px;
}

.bottom-8 {
  bottom: 30px;
}

.left-0 {
  left: 0;
}

.left-\[4px\] {
  left: 4px;
}

.left-\[50\%\] {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-\[50\%\] {
  right: 50%;
}

.top-0 {
  top: 0;
}

.top-8 {
  top: 30px;
}

.top-\[4px\] {
  top: 4px;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[10\] {
  z-index: 10;
}

.z-\[49\] {
  z-index: 49;
}

.z-\[5\] {
  z-index: 5;
}

.z-\[6\] {
  z-index: 6;
}

.z-\[7\] {
  z-index: 7;
}

.z-\[8\] {
  z-index: 8;
}

.z-\[9\] {
  z-index: 9;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.m-1 {
  margin: 1px;
}

.m-5 {
  margin: 8px;
}

.mx-6 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-7 {
  margin-left: 20px;
  margin-right: 20px;
}

.my-10 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.my-8 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.-mt-\[4px\] {
  margin-top: -4px;
}

.mb-10 {
  margin-bottom: 60px;
}

.mb-11 {
  margin-bottom: 90px;
}

.mb-12 {
  margin-bottom: 120px;
}

.mb-13 {
  margin-bottom: 180px;
}

.mb-3 {
  margin-bottom: 4px;
}

.mb-6 {
  margin-bottom: 10px;
}

.mb-7 {
  margin-bottom: 20px;
}

.mb-8 {
  margin-bottom: 30px;
}

.mb-9 {
  margin-bottom: 45px;
}

.mb-\[102px\] {
  margin-bottom: 102px;
}

.mb-\[87px\] {
  margin-bottom: 87px;
}

.ml-3 {
  margin-left: 4px;
}

.ml-4 {
  margin-left: 6px;
}

.ml-5 {
  margin-left: 8px;
}

.ml-\[-66\%\] {
  margin-left: -66%;
}

.mr-4 {
  margin-right: 6px;
}

.mr-5 {
  margin-right: 8px;
}

.mr-7 {
  margin-right: 20px;
}

.mr-\[4px\] {
  margin-right: 4px;
}

.mt-1 {
  margin-top: 1px;
}

.mt-10 {
  margin-top: 60px;
}

.mt-8 {
  margin-top: 30px;
}

.mt-9 {
  margin-top: 45px;
}

.mt-\[4px\] {
  margin-top: 4px;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-1 {
  height: 1px;
}

.h-6 {
  height: 10px;
}

.h-7 {
  height: 20px;
}

.h-\[100\%\] {
  height: 100%;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[100vw\] {
  height: 100vw;
}

.h-\[13px\] {
  height: 13px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[200vw\] {
  height: 200vw;
}

.h-\[300vh\] {
  height: 300vh;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[50\%\] {
  height: 50%;
}

.h-\[7\.5vw\] {
  height: 7.5vw;
}

.h-\[9px\] {
  height: 9px;
}

.h-\[calc\(100\%\+1px\)\] {
  height: calc(100% + 1px);
}

.h-\[calc\(100\%\+3px\)\] {
  height: calc(100% + 3px);
}

.h-\[length\:var\(--block-thickness\)\] {
  height: var(--block-thickness);
}

.h-\[length\:var\(--visible-viewport-height\)\] {
  height: var(--visible-viewport-height);
}

.h-\[var\(--height\)\] {
  height: var(--height);
}

.h-full {
  height: 100%;
}

.h-min {
  height: min-content;
}

.h-screen {
  height: 100vh;
}

.w-1 {
  width: 1px;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-6 {
  width: 10px;
}

.w-7 {
  width: 20px;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[17px\] {
  width: 17px;
}

.w-\[30\%\] {
  width: 30%;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[7\.5vw\] {
  width: 7.5vw;
}

.w-\[70vw\] {
  width: 70vw;
}

.w-\[75\%\] {
  width: 75%;
}

.w-\[7px\] {
  width: 7px;
}

.w-\[85vw\] {
  width: 85vw;
}

.w-\[9px\] {
  width: 9px;
}

.w-\[calc\(100\%\+1px\)\] {
  width: calc(100% + 1px);
}

.w-\[calc\(100\%\+4px\)\] {
  width: calc(100% + 4px);
}

.w-\[length\:var\(--block-thickness\)\] {
  width: var(--block-thickness);
}

.w-full {
  width: 100%;
}

.w-min {
  width: min-content;
}

.w-screen {
  width: 100vw;
}

.max-w-full {
  max-width: 100%;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.basis-\[10\%\] {
  flex-basis: 10%;
}

.basis-\[15\%\] {
  flex-basis: 15%;
}

.basis-\[30\%\] {
  flex-basis: 30%;
}

.basis-\[7\.5\%\] {
  flex-basis: 7.5%;
}

.basis-\[var\(--flex-basis\)\] {
  flex-basis: var(--flex-basis);
}

.origin-bottom {
  transform-origin: bottom;
}

.origin-bottom-left {
  transform-origin: 0 100%;
}

.origin-right {
  transform-origin: 100%;
}

.-translate-x-\[100\%\] {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[100\%\] {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[100\%\] {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-x-\[45deg\] {
  --tw-skew-x: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-skew-y-\[45deg\] {
  --tw-skew-y: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-x-\[45deg\] {
  --tw-skew-x: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-y-\[45deg\] {
  --tw-skew-y: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-\[-1\] {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-y-\[-1\] {
  --tw-scale-y: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.resize {
  resize: both;
}

.appearance-none {
  appearance: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-7 {
  gap: 20px;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-bl-\[2px\] {
  border-bottom-left-radius: 2px;
}

.rounded-br-\[1px\] {
  border-bottom-right-radius: 1px;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-l-0 {
  border-left-width: 0;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-blue {
  --tw-border-opacity: 1;
  border-color: rgb(91 4 232 / var(--tw-border-opacity));
}

.border-gold {
  --tw-border-opacity: 1;
  border-color: rgb(200 161 104 / var(--tw-border-opacity));
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgb(254 74 40 / var(--tw-border-opacity));
}

.border-silver {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-l-gold {
  --tw-border-opacity: 1;
  border-left-color: rgb(200 161 104 / var(--tw-border-opacity));
}

.border-t-gold {
  --tw-border-opacity: 1;
  border-top-color: rgb(200 161 104 / var(--tw-border-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(91 4 232 / var(--tw-bg-opacity));
}

.bg-gold {
  --tw-bg-opacity: 1;
  background-color: rgb(200 161 104 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.p-1 {
  padding: 1px;
}

.p-5 {
  padding: 8px;
}

.p-7 {
  padding: 20px;
}

.px-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.px-3 {
  padding-left: 4px;
  padding-right: 4px;
}

.px-4 {
  padding-left: 6px;
  padding-right: 6px;
}

.py-9 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.pb-10 {
  padding-bottom: 60px;
}

.pb-11 {
  padding-bottom: 90px;
}

.pb-2 {
  padding-bottom: 2px;
}

.pb-6 {
  padding-bottom: 10px;
}

.pb-7 {
  padding-bottom: 20px;
}

.pb-8 {
  padding-bottom: 30px;
}

.pb-9 {
  padding-bottom: 45px;
}

.pb-\[33\%\] {
  padding-bottom: 33%;
}

.pl-0 {
  padding-left: 0;
}

.pl-2 {
  padding-left: 2px;
}

.pl-3 {
  padding-left: 4px;
}

.pl-6 {
  padding-left: 10px;
}

.pl-8 {
  padding-left: 30px;
}

.pr-3 {
  padding-right: 4px;
}

.pr-4 {
  padding-right: 6px;
}

.pr-6 {
  padding-right: 10px;
}

.pr-7 {
  padding-right: 20px;
}

.pr-8 {
  padding-right: 30px;
}

.pt-6 {
  padding-top: 10px;
}

.pt-8 {
  padding-top: 30px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-2xl {
  font-size: 60px;
  line-height: 100%;
}

.text-3xl {
  font-size: 70px;
  line-height: 85%;
}

.text-4xl {
  font-size: 80px;
  line-height: 85%;
}

.text-5xl {
  font-size: 150px;
  line-height: 85%;
}

.text-\[0px\] {
  font-size: 0;
}

.text-\[1\.22vw\] {
  font-size: 1.22vw;
}

.text-\[1\.24vw\] {
  font-size: 1.24vw;
}

.text-\[1\.34vw\] {
  font-size: 1.34vw;
}

.text-\[1\.65vw\] {
  font-size: 1.65vw;
}

.text-\[12\.5vw\] {
  font-size: 12.5vw;
}

.text-\[14vw\] {
  font-size: 14vw;
}

.text-\[165vw\] {
  font-size: 165vw;
}

.text-\[16vw\] {
  font-size: 16vw;
}

.text-\[17vw\] {
  font-size: 17vw;
}

.text-\[2\.85vw\] {
  font-size: 2.85vw;
}

.text-\[22vw\] {
  font-size: 22vw;
}

.text-\[28vw\] {
  font-size: 28vw;
}

.text-\[3\.25vw\] {
  font-size: 3.25vw;
}

.text-\[3\.6vw\] {
  font-size: 3.6vw;
}

.text-\[4\.95vw\] {
  font-size: 4.95vw;
}

.text-\[56vw\] {
  font-size: 56vw;
}

.text-\[6vw\] {
  font-size: 6vw;
}

.text-\[8\.5vw\] {
  font-size: 8.5vw;
}

.text-\[9\.25vw\] {
  font-size: 9.25vw;
}

.text-\[length\:--font-size\] {
  font-size: var(--font-size);
}

.text-base {
  letter-spacing: .02em;
  font-size: 18px;
  line-height: 150%;
}

.text-lg {
  letter-spacing: 0;
  font-size: 40px;
  line-height: 95%;
}

.text-md {
  letter-spacing: .01em;
  font-size: 30px;
  line-height: 135%;
}

.text-rg {
  letter-spacing: .02em;
  font-size: 22px;
  line-height: 125%;
}

.text-sm {
  font-size: 16px;
  line-height: 140%;
}

.text-xl {
  letter-spacing: 0;
  font-size: 50px;
  line-height: 135%;
}

.text-xs {
  font-size: 14px;
  line-height: 100%;
}

.text-xxs {
  font-size: 10px;
  line-height: 100%;
}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic;
}

.leading-\[100\%\] {
  line-height: 100%;
}

.leading-\[107\%\] {
  line-height: 107%;
}

.leading-\[110\%\] {
  line-height: 110%;
}

.leading-\[115\%\] {
  line-height: 115%;
}

.leading-\[120\%\] {
  line-height: 120%;
}

.leading-\[121\%\] {
  line-height: 121%;
}

.leading-\[138\%\] {
  line-height: 138%;
}

.leading-\[140\%\] {
  line-height: 140%;
}

.leading-\[40\%\] {
  line-height: 40%;
}

.leading-\[45\%\] {
  line-height: 45%;
}

.leading-\[55\%\] {
  line-height: 55%;
}

.leading-\[60\%\] {
  line-height: 60%;
}

.leading-\[65\%\] {
  line-height: 65%;
}

.leading-\[79\%\] {
  line-height: 79%;
}

.leading-\[80\%\] {
  line-height: 80%;
}

.leading-\[85\%\] {
  line-height: 85%;
}

.leading-none {
  line-height: 1;
}

.\!tracking-\[0\.1em\] {
  letter-spacing: .1em !important;
}

.tracking-\[0\.02em\] {
  letter-spacing: .02em;
}

.tracking-\[0\.1em\] {
  letter-spacing: .1em;
}

.tracking-normal {
  letter-spacing: 0;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(91 4 232 / var(--tw-text-opacity));
}

.text-gold {
  --tw-text-opacity: 1;
  color: rgb(200 161 104 / var(--tw-text-opacity));
}

.text-silver {
  --tw-text-opacity: 1;
  color: rgb(217 217 217 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-2 {
  outline-width: 2px;
}

.outline-\[1px\] {
  outline-width: 1px;
}

.outline-gold {
  outline-color: #c8a168;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.\[--font-opsz\:--opsz-display\] {
  --font-opsz: var(--opsz-display);
}

.\[--font-opsz\:--opsz-micro\] {
  --font-opsz: var(--opsz-micro);
}

.\[--font-opsz\:--opsz-text\] {
  --font-opsz: var(--opsz-text);
}

.\[--font-wght\:--wght-black\] {
  --font-wght: var(--wght-black);
}

.\[--font-wght\:--wght-bold\] {
  --font-wght: var(--wght-bold);
}

.\[--font-wght\:--wght-light\] {
  --font-wght: var(--wght-light);
}

.\[--font-wght\:--wght-medium\] {
  --font-wght: var(--wght-medium);
}

.\[--font-wght\:--wght-regular\] {
  --font-wght: var(--wght-regular);
}

.\[direction\:ltr\] {
  direction: ltr;
}

.\[font-variation-settings\:inherit\] {
  font-variation-settings: inherit;
}

.\[writing-mode\:vertical-lr\] {
  writing-mode: vertical-lr;
}

html {
  --visible-viewport-height: 100vh;
  --global-perspective: 250px;
}

:root {
  --opsz-micro: 10;
  --opsz-text: 18;
  --opsz-display: 30;
  --wght-light: 300;
  --wght-regular: 400;
  --wght-medium: 500;
  --wght-bold: 700;
  --wght-black: 900;
  --font-opsz: 18;
  --font-wght: 400;
}

* {
  font-variation-settings: "wght" var(--font-wght), "opsz" var(--font-opsz);
}

html, body {
  font-family: var(--upright-fonts);
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(217 217 217 / var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-weight: 400;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

html ::selection, body ::selection, html::selection, body::selection {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  background-color: #c8a168fe;
}

.content {
  margin: 0 15px;
}

@media (width >= 768px) {
  .content {
    margin: 0 25px;
  }
}

@media (width >= 1024px) {
  .content {
    margin: 0 150px;
  }
}

@media (width >= 1280px) {
  .content {
    margin: 0 225px;
  }
}

body {
  --wght: 100;
  --wght-text: "100";
  --opsz-text: "20";
  --wdth: 100;
  --wdth-text: "100";
  --ital: 0;
  --ital-text: "0";
  --CNTR: 100;
  --font-size-text: "50";
}

a {
  cursor: pointer;
}

em {
  font-family: var(--italic-fonts);
  font-style: normal;
}

[data-component="bb__variable-value"]:after {
  font-variant-numeric: tabular-nums;
  content: var(--x-variable-value-text);
}

[data-component="aaa__height"]:after {
  font-variant-numeric: tabular-nums;
  content: var(--height-text);
}

[data-component="aaa__font-size"]:after {
  font-variant-numeric: tabular-nums;
  content: var(--font-size-text);
}

[data-component="aaa__opsz-text"]:after, [data-component="axes-specimen__variable-value"][data-variable="--font-opsz"]:after {
  font-variant-numeric: tabular-nums;
  content: var(--font-opsz-text);
}

[data-component="axes-specimen__variable-value"][data-variable="--font-wght"]:after {
  font-variant-numeric: tabular-nums;
  content: var(--font-wght-text);
}

[data-component="nav__inner"] {
  will-change: transform;
  transform: translateY(var(--top));
}

@supports ((-moz-appearance: none)) {
  .moz-fix-1 {
    line-height: 85% !important;
  }

  .moz-fix-2 {
    line-height: 110% !important;
  }

  .moz-fix-3 {
    line-height: 90% !important;
  }

  .moz-fix-4 {
    line-height: 110% !important;
  }
}

.checked\:border-blue:checked {
  --tw-border-opacity: 1;
  border-color: rgb(91 4 232 / var(--tw-border-opacity));
}

.checked\:border-red:checked {
  --tw-border-opacity: 1;
  border-color: rgb(254 74 40 / var(--tw-border-opacity));
}

.checked\:border-white:checked {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.checked\:bg-blue:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(91 4 232 / var(--tw-bg-opacity));
}

.checked\:bg-red:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(254 74 40 / var(--tw-bg-opacity));
}

.checked\:bg-white:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-blue:hover {
  --tw-border-opacity: 1;
  border-color: rgb(91 4 232 / var(--tw-border-opacity));
}

.hover\:border-red:hover {
  --tw-border-opacity: 1;
  border-color: rgb(254 74 40 / var(--tw-border-opacity));
}

.hover\:border-silver:hover {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
}

.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.hover\:bg-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(91 4 232 / var(--tw-bg-opacity));
}

.hover\:bg-red:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 74 40 / var(--tw-bg-opacity));
}

.hover\:bg-silver:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-gold:hover {
  --tw-text-opacity: 1;
  color: rgb(200 161 104 / var(--tw-text-opacity));
}

.hover\:text-silver:hover {
  --tw-text-opacity: 1;
  color: rgb(217 217 217 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:\[--font-wght\:--wght-bold\]:hover {
  --font-wght: var(--wght-bold);
}

.group:hover .group-hover\:border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.group:hover .group-hover\:bg-silver {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:fill-gold {
  fill: #c8a168;
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group:hover .group-hover\:\[--font-wght\:--wght-bold\] {
  --font-wght: var(--wght-bold);
}

@media (width >= 1180px) {
  .md\:top-4 {
    top: 6px;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-start-12 {
    grid-column-start: 12;
  }

  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:col-start-3 {
    grid-column-start: 3;
  }

  .md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:col-start-6 {
    grid-column-start: 6;
  }

  .md\:col-start-9 {
    grid-column-start: 9;
  }

  .md\:m-5 {
    margin: 8px;
  }

  .md\:mx-10 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-11 {
    margin-bottom: 90px;
  }

  .md\:mb-12 {
    margin-bottom: 120px;
  }

  .md\:mb-13 {
    margin-bottom: 180px;
  }

  .md\:mb-4 {
    margin-bottom: 6px;
  }

  .md\:mb-7 {
    margin-bottom: 20px;
  }

  .md\:mb-8 {
    margin-bottom: 30px;
  }

  .md\:mb-9 {
    margin-bottom: 45px;
  }

  .md\:mb-\[88px\] {
    margin-bottom: 88px;
  }

  .md\:ml-6 {
    margin-left: 10px;
  }

  .md\:ml-\[-50\%\] {
    margin-left: -50%;
  }

  .md\:mr-6 {
    margin-right: 10px;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-11 {
    margin-top: 90px;
  }

  .md\:mt-12 {
    margin-top: 120px;
  }

  .md\:mt-13, .md\:mt-\[180px\] {
    margin-top: 180px;
  }

  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-2 {
    height: 2px;
  }

  .md\:h-7 {
    height: 20px;
  }

  .md\:h-9 {
    height: 45px;
  }

  .md\:h-\[250px\] {
    height: 250px;
  }

  .md\:h-\[350px\] {
    height: 350px;
  }

  .md\:h-\[450px\] {
    height: 450px;
  }

  .md\:min-h-\[40px\] {
    min-height: 40px;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-2 {
    width: 2px;
  }

  .md\:w-7 {
    width: 20px;
  }

  .md\:w-9 {
    width: 45px;
  }

  .md\:w-\[30\%\] {
    width: 30%;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:basis-\[50\%\] {
    flex-basis: 50%;
  }

  .md\:basis-\[7\.5\%\] {
    flex-basis: 7.5%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:border-2 {
    border-width: 2px;
  }

  .md\:border-b {
    border-bottom-width: 1px;
  }

  .md\:border-b-0 {
    border-bottom-width: 0;
  }

  .md\:border-l-0 {
    border-left-width: 0;
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:p-6 {
    padding: 10px;
  }

  .md\:px-6 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .md\:px-\[5px\] {
    padding-left: 5px;
    padding-right: 5px;
  }

  .md\:py-11 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:pb-10 {
    padding-bottom: 60px;
  }

  .md\:pb-11 {
    padding-bottom: 90px;
  }

  .md\:pb-12 {
    padding-bottom: 120px;
  }

  .md\:pb-13 {
    padding-bottom: 180px;
  }

  .md\:pb-6 {
    padding-bottom: 10px;
  }

  .md\:pb-7 {
    padding-bottom: 20px;
  }

  .md\:pb-8 {
    padding-bottom: 30px;
  }

  .md\:pb-9 {
    padding-bottom: 45px;
  }

  .md\:pb-\[105px\] {
    padding-bottom: 105px;
  }

  .md\:pb-\[2em\] {
    padding-bottom: 2em;
  }

  .md\:pl-6 {
    padding-left: 10px;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pr-7 {
    padding-right: 20px;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pt-8 {
    padding-top: 30px;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-3xl {
    font-size: 70px;
    line-height: 85%;
  }

  .md\:text-4xl {
    font-size: 80px;
    line-height: 85%;
  }

  .md\:text-5xl {
    font-size: 150px;
    line-height: 85%;
  }

  .md\:text-6xl {
    font-size: 14.5vw;
    line-height: 55%;
  }

  .md\:text-\[100px\] {
    font-size: 100px;
  }

  .md\:text-\[15\.5vw\] {
    font-size: 15.5vw;
  }

  .md\:text-\[15vw\] {
    font-size: 15vw;
  }

  .md\:text-\[22vw\] {
    font-size: 22vw;
  }

  .md\:text-\[2vw\] {
    font-size: 2vw;
  }

  .md\:text-\[3\.95vw\] {
    font-size: 3.95vw;
  }

  .md\:text-\[6\.5vw\] {
    font-size: 6.5vw;
  }

  .md\:text-\[6\.75vw\] {
    font-size: 6.75vw;
  }

  .md\:text-base {
    letter-spacing: .02em;
    font-size: 18px;
    line-height: 150%;
  }

  .md\:text-md {
    letter-spacing: .01em;
    font-size: 30px;
    line-height: 135%;
  }

  .md\:text-rg {
    letter-spacing: .02em;
    font-size: 22px;
    line-height: 125%;
  }

  .md\:text-sm {
    font-size: 16px;
    line-height: 140%;
  }

  .md\:text-xl {
    letter-spacing: 0;
    font-size: 50px;
    line-height: 135%;
  }

  .md\:text-xs {
    font-size: 14px;
    line-height: 100%;
  }

  .md\:uppercase {
    text-transform: uppercase;
  }

  .md\:leading-\[125\%\] {
    line-height: 125%;
  }

  .md\:leading-\[130\%\] {
    line-height: 130%;
  }

  .md\:leading-\[135\%\] {
    line-height: 135%;
  }

  .md\:leading-\[42\.5\%\] {
    line-height: 42.5%;
  }

  .md\:leading-\[45\%\] {
    line-height: 45%;
  }

  .md\:leading-\[75\%\] {
    line-height: 75%;
  }

  .md\:leading-\[90\%\] {
    line-height: 90%;
  }

  .md\:\[--font-opsz\:--opsz-display\] {
    --font-opsz: var(--opsz-display);
  }

  .md\:\[--font-opsz\:--opsz-micro\] {
    --font-opsz: var(--opsz-micro);
  }

  .md\:\[--font-opsz\:--opsz-text\] {
    --font-opsz: var(--opsz-text);
  }

  .md\:\[--font-wght\:--wght-light\] {
    --font-wght: var(--wght-light);
  }
}

@media (width >= 1440px) {
  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-5 {
    grid-column-start: 5;
  }

  .lg\:mt-\[220px\] {
    margin-top: 220px;
  }

  .lg\:h-\[400px\] {
    height: 400px;
  }

  .lg\:h-\[550px\] {
    height: 550px;
  }

  .lg\:h-\[700px\] {
    height: 700px;
  }

  .lg\:text-\[6vw\] {
    font-size: 6vw;
  }
}

@media (width >= 2000px) {
  .xxl\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xxl\:col-start-3 {
    grid-column-start: 3;
  }
}

@media (aspect-ratio <= .5) {
  .narrow\:h-\[100vh\] {
    height: 100vh;
  }

  .narrow\:w-\[50vh\] {
    width: 50vh;
  }
}

.\[\&\:\:-moz-range-thumb\]\:block::-moz-range-thumb {
  display: block;
}

.\[\&\:\:-moz-range-thumb\]\:h-8::-moz-range-thumb {
  height: 30px;
}

.\[\&\:\:-moz-range-thumb\]\:w-8::-moz-range-thumb {
  width: 30px;
}

.\[\&\:\:-moz-range-thumb\]\:appearance-none::-moz-range-thumb {
  appearance: none;
}

.\[\&\:\:-webkit-slider-thumb\]\:block::-webkit-slider-thumb {
  display: block;
}

.\[\&\:\:-webkit-slider-thumb\]\:h-8::-webkit-slider-thumb {
  height: 30px;
}

.\[\&\:\:-webkit-slider-thumb\]\:w-8::-webkit-slider-thumb {
  width: 30px;
}

.\[\&\:\:-webkit-slider-thumb\]\:appearance-none::-webkit-slider-thumb {
  appearance: none;
}
/*# sourceMappingURL=index.11c9738b.css.map */
