/* @import "../../node_modules/tailwindcss/base";
@import "../../node_modules/tailwindcss/components";
@import "../../node_modules/tailwindcss/utilities"; */

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

/* @import "components/loader.css"; */
@import "base/grid.css";
@import "base/typography.css";
@import "base/animation.css";
@import "components/nav.css";
@import "components/character-set.css";
@import "components/typetester.css";
@import "components/sliders.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  --visible-viewport-height: 100vh;
  --global-perspective: 250px;
}

:root {
  --opsz-micro: 10;
  --opsz-text: 18;
  --opsz-display: 30;
  --wght-light: 300;
  --wght-regular: 400;
  --wght-medium: 500;
  --wght-bold: 700;
  --wght-black: 900;
  --font-opsz: 18;
  --font-wght: 400;
}

* {
  font-variation-settings: "wght" var(--font-wght), "opsz" var(--font-opsz);
}

html,
body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  font-family: var(--upright-fonts);
  font-weight: 400;
  @apply bg-black text-silver selection:bg-[#C8A168fe] selection:text-black antialiased;
}

.content {
  margin-top: 0;
  margin-right: 15px;
  margin-bottom: 0;
  margin-left: 15px;
}
@media (min-width: 768px) {
  .content {
    margin-top: 0;
    margin-right: 25px;
    margin-bottom: 0;
    margin-left: 25px;
  }
}
@media (min-width: 1024px) {
  .content {
    margin-top: 0;
    margin-right: 150px;
    margin-bottom: 0;
    margin-left: 150px;
  }
}

@media (min-width: 1280px) {
  .content {
    margin-top: 0;
    margin-right: 225px;
    margin-bottom: 0;
    margin-left: 225px;
  }
}

/* Comment this out for development because it's annoying to have it locked!! */
/* body.locked {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--visible-viewport-height);
  pointer-events: none;
}

@media (hover: none) {
  body.locked {
    overflow: hidden;
  }
} */

/* body::-webkit-scrollbar {
  width: 15px;
}
body::-webkit-scrollbar-thumb {
  @apply bg-gray hover:bg-green transition-colors;
}
body::-webkit-scrollbar-track {
  @apply bg-black;
  border-left: 1px solid #949494;
} */

body {
  /* defaults for variable fonts vars */
  /* You can overwrite these global defaults using the style attribute of a [data-component*="axes-overview__sample"] */
  --wght: 100;
  --wght-text: "100";
  --opsz-text: "20";
  --wdth: 100;
  --wdth-text: "100";
  --ital: 0;
  --ital-text: "0";
  --CNTR: 100;
  --font-size-text: "50";
}

a {
  @apply cursor-pointer;
}

em {
  font-family: var(--italic-fonts);
  font-style: normal;
}

/* [data-component*="axes-overview__sample"] {
  font-variation-settings: "wght" var(--wght), "wdth" var(--wdth),
    "CNTR" var(--CNTR);
}

[data-component*="axes-overview__wdth-text"]:before {
  content: var(--wdth-text);
}

[data-component*="axes-overview__wght-text"]:before {
  content: var(--wght-text);
}

[data-component*="axes-overview__font-size-text"]:before {
  content: var(--font-size-text);
}

[data-component*="show-hide-section__section"][hidden] {
  display: none;
} */
[data-component="bb__variable-value"]::after {
  font-variant-numeric: tabular-nums;
  content: var(--x-variable-value-text);
}

[data-component="aaa__height"]::after {
  font-variant-numeric: tabular-nums;
  content: var(--height-text);
}

[data-component="aaa__font-size"]::after {
  font-variant-numeric: tabular-nums;
  content: var(--font-size-text);
}

[data-component="aaa__opsz-text"]::after {
  font-variant-numeric: tabular-nums;
  content: var(--font-opsz-text);
}

[data-component="axes-specimen__variable-value"][data-variable="--font-opsz"]::after {
  font-variant-numeric: tabular-nums;
  content: var(--font-opsz-text);
}

[data-component="axes-specimen__variable-value"][data-variable="--font-wght"]::after {
  font-variant-numeric: tabular-nums;
  content: var(--font-wght-text);
}

[data-component="nav__inner"] {
  will-change: transform;
  transform: translateY(var(--top));
}

/* Extremely ugly fix for varying line-height of spans in blocks in Firefox */

@supports (-moz-appearance: none) {
  .moz-fix-1 {
    line-height: 85% !important;
  }
  .moz-fix-2 {
    line-height: 110% !important;
  }
  .moz-fix-3 {
    line-height: 90% !important;
  }
  .moz-fix-4 {
    line-height: 110% !important;
  }
}
