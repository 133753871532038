@charset "UTF-8";
@tailwind base;

/* CHARACTER SET GRID */

.single-item {
  @apply flex justify-center items-center 
  w-[calc((100%/10)-0.05px)] 
  md:w-[calc((100%/24)-0.05px)] 
  lg:w-[calc((100%/32)-0.05px)] aspect-[1/1] text-base md:text-rg;
  box-shadow: 1px 0 0 0 silver, 0 1px 0 0 silver, 1px 1px 0 0 silver,
    1px 0 0 0 silver inset, 0 1px 0 0 silver inset;
}

.single-item {
  @apply hover:bg-gold hover:text-black hover:cursor-pointer [--font-opsz:--opsz-micro];
}

/* CHARSET LOUPE */

.charset-highlight.zoomed {
  @apply fixed top-0 left-0 bg-black border border-gold text-gold text-center pointer-events-none transition-opacity ease-in-out w-[200px] h-[200px] md:w-[300px] md:h-[300px] lg:w-[400px] lg:h-[400px] text-[31vw] leading-[1.575] md:text-[22vw] md:leading-[1.575] lg:text-[15vw] lg:leading-[1.5] overflow-hidden;
}

[data-component="char-set__unicode"]:before {
  content: var(--unicode);
}

[data-component="char-set__script"]:before {
  content: var(--script);
}

[data-component="char-set__subset"]:before {
  content: var(--subset);
}

[data-component="char-set__subset-size"]:before {
  content: var(--subset-size);
}

[data-component="char-set__loupe"]:before {
  content: var(--char);
  font-feature-settings: var(--font-feature-settings);
}
