@charset "UTF-8";
@tailwind base;
@tailwind components;

/* body:not(.tt-active) .tt,
body:not(.tt-active) button[data-component="tt-close"] {
  display: none !important;
} */

/* Add back to this rule once JS works  */
body:not(.tt-active) .tt,
body:not(.tt-active) button[data-component="tt-close"] {
  @apply -translate-y-[100vh] transition-transform duration-500;
}

/* body:not(.tt-active) .tt * {
  @apply pointer-events-none;
} */

.tt {
  @apply overflow-hidden;
  transition: transform 1s;
}

/* body.tt-teaser .tt {
  @apply -translate-y-[98.5vh] transition-transform duration-100;
} */

/* pt-8 pb-9 */

body.tt-active .tt {
  @apply translate-y-0 pointer-events-auto transition-transform duration-500;
}

[data-component="tt__sample-text"] span {
  @apply leading-[100%] md:leading-[95%];
}

.tt-sample {
  @apply text-[40vw] md:text-[16vw] leading-[100%] md:leading-[100%] pointer-events-none;
}

[data-component="tt__sample-options"] {
}

/* Sample Options */

.tt-controls-container {
  @apply p-5 flex flex-col md:flex-row md:flex-grow [--font-opsz:--opsz-micro] md:min-h-[80px] md:w-[50vw];
}

.tt-element-container {
  @apply border p-6 md:p-6 border-silver flex flex-row items-center flex-grow min-h-9 md:min-h-0;
}

/* Sliders */

.typetester-slider-container {
  @apply h-full w-full flex flex-grow items-center;
}

.typetester-slider-label {
  @apply w-1/4 md:w-full text-left pr-6;
}

.typetester-slider {
  @apply appearance-none h-1 bg-silver outline-none flex flex-grow hover:cursor-pointer;
}

.typetester-slider::-webkit-slider-thumb {
  @apply bg-silver appearance-none w-[15px] h-[15px] cursor-pointer rounded-full hover:bg-white transition-all;
}

.typetester-slider::-moz-range-thumb {
  @apply bg-silver appearance-none w-[15px] h-[15px] cursor-pointer rounded-full hover:bg-white transition-all;
}

[data-component="tt__wdth-value"],
[data-component="tt__wght-value"],
[data-component="tt__opsz-value"] {
  @apply w-9 text-right flex justify-end items-center md:w-full md:justify-start;
}

[data-component="tt__opsz-value"]:after {
  content: var(--font-opsz-text);
}

[data-component="tt__wght-value"]:after {
  content: var(--font-wght-text);
}

[data-component="tt__sample"] [data-component="tt__sample-text"] {
  font-variation-settings: "wght" var(--font-wght), "opsz" var(--font-opsz);
}

/* Checkboxes */

[data-component="tt__settings-btn"][data-active="true"]
  .typetester-settings-toggle-indicator {
  @apply bg-silver rotate-0 transition-all;
}
