@charset "UTF-8";

/* --- GT America Intl VAR FONTS ––– */

/* Upright, Axes: wght, opsz */

@font-face {
  font-family: "GT Pantheon";
  src: url("../../fonts/GT-Pantheon-VF.woff2") format("woff2-variations");
  font-weight: normal;
}

/* Italic, Axes: wght, opsz */

@font-face {
  font-family: "GT Pantheon Italic";
  src: url("../../fonts/GT-Pantheon-Italic-VF.woff2") format("woff2-variations");
  font-weight: normal;
  font-style: normal;
}

/* Specialty Font, Show Contrast, Axis: CNTR, values 0–100 */

/* @font-face {
  font-family: "GT America Mow";
  src: url("../../fonts/specialty/GT-America-mow-VF.woff2")
    format("woff2-variations");
  font-weight: normal;
} */

/* Setting up font family variables */

:root {
  --upright-fonts: "GT Pantheon";
  --italic-fonts: "GT Pantheon Italic";
}

.italic-font {
  font-family: var(--italic-fonts);
  font-style: normal;
}

/* .specialty-font-contrast {
  font-family: "GT America Contrast";
} */

/* GT Pantheon */

.font-micro-light {
  --font-wght: var(--wght-light);
  --font-opsz: var(--opsz-micro);
}

.font-micro-regular {
  --font-wght: var(--wght-regular);
  --font-opsz: var(--opsz-micro);
}

.font-micro-medium {
  --font-wght: var(--wght-medium);
  --font-opsz: var(--opsz-micro);
}

.font-micro-bold {
  --font-wght: var(--wght-bold);
  --font-opsz: var(--opsz-micro);
}

.font-micro-black {
  --font-wght: var(--wght-black);
  --font-opsz: var(--opsz-micro);
}

.font-text-light {
  --font-wght: var(--wght-light);
  --font-opsz: var(--opsz-text);
}

.font-text-regular {
  --font-wght: var(--wght-regular);
  --font-opsz: var(--opsz-text);
}

.font-text-medium {
  --font-wght: var(--wght-medium);
  --font-opsz: var(--opsz-text);
}

.font-text-bold {
  --font-wght: var(--wght-bold);
  --font-opsz: var(--opsz-text);
}

.font-text-black {
  --font-wght: var(--wght-black);
  --font-opsz: var(--opsz-text);
}

.font-display-light {
  --font-wght: var(--wght-light);
  --font-opsz: var(--opsz-display);
}

.font-display-regular {
  --font-wght: var(--wght-regular);
  --font-opsz: var(--opsz-display);
}

.font-display-medium {
  --font-wght: var(--wght-medium);
  --font-opsz: var(--opsz-display);
}

.font-display-bold {
  --font-wght: var(--wght-bold);
  --font-opsz: var(--opsz-display);
}

.font-display-black {
  --font-wght: var(--wght-black);
  --font-opsz: var(--opsz-display);
}

.font-ital-micro-light {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-light);
  --font-opsz: var(--opsz-micro);
}

.font-ital-micro-regular {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-regular);
  --font-opsz: var(--opsz-micro);
}

.font-ital-micro-medium {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-medium);
  --font-opsz: var(--opsz-micro);
}

.font-ital-micro-bold {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-bold);
  --font-opsz: var(--opsz-micro);
}

.font-ital-micro-black {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-black);
  --font-opsz: var(--opsz-micro);
}

.font-ital-text-light {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-light);
  --font-opsz: var(--opsz-text);
}

.font-ital-text-regular {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-regular);
  --font-opsz: var(--opsz-text);
}

.font-ital-text-medium {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-medium);
  --font-opsz: var(--opsz-text);
}

.font-ital-text-bold {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-bold);
  --font-opsz: var(--opsz-text);
}

.font-ital-text-black {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-black);
  --font-opsz: var(--opsz-text);
}

.font-ital-display-light {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-light);
  --font-opsz: var(--opsz-display);
}

.font-ital-display-regular {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-regular);
  --font-opsz: var(--opsz-display);
}

.font-ital-display-medium {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-medium);
  --font-opsz: var(--opsz-display);
}

.font-ital-display-bold {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-bold);
  --font-opsz: var(--opsz-display);
}

.font-ital-display-black {
  font-family: var(--italic-fonts);
  --font-wght: var(--wght-black);
  --font-opsz: var(--opsz-display);
}

h1,
.h1 {
}

h2,
.h2 {
}

h3,
.h3 {
}

h4,
.h4 {
}

h5,
.h5 {
}

h6,
.h6 {
}

h7,
.h7 {
}

nav a {
  @apply hover:text-black;
}

/* Text Styles */

.small-caps {
  line-height: 1em;
  font-feature-settings: "smcp" 1, "kern" 1;
}

.all-caps,
.caps {
  line-height: 1em;
  text-transform: uppercase;
}

.text-xs.all-caps,
.text-xs.small-caps {
  letter-spacing: 0.03em;
}

/* OpenType features */

.ot-onum {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "pnum" 1;
  font-feature-settings: "kern" 1, "onum" 1, "pnum" 1;
}

.ot-onum-tnum {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "tnum" 1;
  font-feature-settings: "kern" 1, "onum" 1, "tnum" 1;
}

.ot-lnum {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "pnum" 1;
  font-feature-settings: "kern" 1, "lnum" 1, "pnum" 1;
}

.ot-lnum-tnum {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "tnum" 1;
  font-feature-settings: "kern" 1, "lnum" 1, "tnum" 1;
}

.ot-dlig {
  -webkit-font-feature-settings: "kern" 1, "liga" 1, "dlig" 1;
  font-feature-settings: "kern" 1, "liga" 1, "dlig" 1;
}

.ot-sups {
  text-transform: lowercase;
  -webkit-font-feature-settings: "kern" 1, "sups" 1;
  font-feature-settings: "kern" 1, "sups" 1;
}

.ot-onum-slash {
  -webkit-font-feature-settings: "kern" 1, "onum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "onum" 1, "zero" 1;
}

.ot-lnum-slash {
  -webkit-font-feature-settings: "kern" 1, "lnum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "lnum" 1, "zero" 1;
}

.ot-tf-onum-slash {
  -webkit-font-feature-settings: "kern" 1, "tnum" 1, "onum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "tnum" 1, "onum" 1, "zero" 1;
}

.ot-tf-lnum-slash {
  -webkit-font-feature-settings: "kern" 1, "tnum" 1, "lnum" 1, "zero" 1;
  font-feature-settings: "kern" 1, "tnum" 1, "lnum" 1, "zero" 1;
}

.ot-osf {
  -webkit-font-feature-settings: "onum" 1, "kern" 1;
  font-feature-settings: "onum" 1, "kern" 1;
}

/* so we don't break typetester when oldstyle figures and smallcaps are both selected */
.ot-osf.small-caps {
  -webkit-font-feature-settings: "smcp" 1, "kern" 1, "onum" 1, "pnum" 1;
  font-feature-settings: "smcp" 1, "kern" 1, "onum" 1, "pnum" 1;
}

.ot-sc-osf {
  font-feature-settings: "smcp" 1, "onum" 1, "kern" 1;
  -webkit-font-feature-settings: "smcp" 1, "onum" 1, "kern" 1;
}

.ot-ssplusarrow {
  -webkit-font-feature-settings: "kern" 1, "ss01" 1, "ss02" 1, "ss03" 1,
    "ss04" 1, "ss05" 1;
  font-feature-settings: "kern" 1, "ss01" 1, "ss02" 1, "ss03" 1, "ss04" 1,
    "ss05" 1;
}

.ot-salt {
  -webkit-font-feature-settings: "calt" 1, "kern" 1;
  font-feature-settings: "calt" 1, "kern" 1;
}

.ot-smcp {
  -webkit-font-feature-settings: "smcp" 1, "kern" 1, "c2sc" 1;
  font-feature-settings: "smcp" 1, "kern" 1, "c2sc" 1;
}

.ot-case {
  -webkit-font-feature-settings: "case" 1, "kern" 1;
  font-feature-settings: "case" 1, "kern" 1;
}

.ot-ss01 {
  -webkit-font-feature-settings: "ss01" 1, "kern" 1;
  font-feature-settings: "ss01" 1, "kern" 1;
}

.ot-ss05 {
  -webkit-font-feature-settings: "ss05" 1, "kern" 1;
  font-feature-settings: "ss05" 1, "kern" 1;
}

.ot-aalt {
  -webkit-font-feature-settings: "aalt" 1, "kern" 1;
  font-feature-settings: "aalt" 1, "kern" 1;
}

.hover-circular-dots:hover {
  -webkit-font-feature-settings: "ss05" 1, "kern" 1;
  font-feature-settings: "ss05" 1, "kern" 1;
}
