@charset "UTF-8";
@tailwind base;

.two-axes-slider-thumb-rectangle::-webkit-slider-thumb,
.two-axes-slider-thumb-rectangle::-moz-range-thumb {
  background: #000; /* Thumb color */
  border: 2px solid #c8a168; /* Add border to the thumb */
  border-radius: 0%; /* Make the thumb circular */
  @apply border md:border-2;
}

.two-axes-slider-thumb-round::-webkit-slider-thumb,
.two-axes-slider-thumb-round::-moz-range-thumb {
  background: #000; /* Thumb color */
  border: 2px solid #c8a168; /* Add border to the thumb */
  border-radius: 50%; /* Make the thumb circular */
  @apply border md:border-2;
}
