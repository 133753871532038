/* nav {
  will-change: background-color, color, border-color;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  background-color: var(--section-background-color);
  color: var(--section-text-color);
  border-color: var(--section-text-color);
  transform: translateY(-100%);
}

nav.nav--is-visible {
  transform: translateY(0);
} */

.sticky-nav-item {
  height: var(--height);
  color: black;
}

.sticky-nav-item > button {
  top: var(--inner-top);
}

[data-component="sticky-subnav"] {
  top: var(--top);
}

[data-component="mobile-scrolly-header"] {
  will-change: transform;
  transform: translateY(var(--top));
}

/* 
.nav-item-typetester {
  @apply -translate-y-12 transition-transform;
}

.nav-items-sections {
  @apply -translate-x-12 transition-transform;
}

.fonts-loaded .nav-item-typetester,
.fonts-loaded .nav-items-sections {
  @apply translate-y-0 translate-x-0 transition-transform;
} */
