@charset "UTF-8";

.grid-setup {
  @apply grid grid-cols-4 md:grid-cols-12 gap-x-6 md:gap-x-7;
}

/* --- General Grid Classes for major layout cases ––– */

.layout_full-width {
}

.layout_main-column-only {
}

.layout_main-plus-section-nav {
}

.layout_main-plus-side-column {
}
