.js-animation-paused,
.js-animation-paused * {
  -webkit-animation-play-state: paused !important;
  animation-play-state: paused !important;
}

.animation-lottie {
  @apply relative;
}

.is-init #loader-section {
  @apply -translate-y-full transition-transform;
}

.h1-reveal h1 {
  background: linear-gradient(
    90deg,
    #c8a168 0%,
    transparent 90%,
    transparent 100%
  );
  background-size: 500% 100%;
  /* I figured this would work (with the hover definition below) but can’t seem to get it to work */
  background-position-x: 100%;
  background-repeat: no-repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  transition: background-position-x 0.2s ease-out;
}

.h1-reveal:hover h1 {
  background-position-x: 0%;
  transition: background-position-x 0.2s ease-in;
}

.anim-design-family-overview {
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-name: animation-design-family-overview;
}

@keyframes animation-design-family-overview {
  0% {
    font-variation-settings: "opsz" 10, "wght" var(--font-wght);
  }
  50% {
    font-variation-settings: "opsz" 17, "wght" var(--font-wght);
  }
  100% {
    font-variation-settings: "opsz" 32, "wght" var(--font-wght);
  }
}

.morph-station {
  /* background-image: linear-gradient(
    90deg,
    #645134 0%,
    #645134 20%,
    #c8a168 40%,
    #c8a168 60%,
    #645134 80%,
    #645134 100%
  ); */

  background-image: linear-gradient(
    90deg,
    #645134 0%,
    #645134 30%,
    #c8a168 42%,
    #c8a168 58%,
    #645134 70%,
    #645134 100%
  );
  background-size: 200% 100%;
  background-position-x: 0%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-name: morph-stations--pingpong--3-1;
}

/* .anim-morph-stations--pingpong--3-1 {
  animation-name: morph-stations--pingpong--3-1;
}

.anim-morph-stations--pingpong--3-2 {
  animation-name: morph-stations--pingpong--3-2;
}

.anim-morph-stations--pingpong--3-3 {
  animation-name: morph-stations--pingpong--3-3;
} */

@keyframes morph-stations--pingpong--3-1 {
  from {
    background-position-x: 100%;
  }
  to {
    background-position-x: 0%;
  }
}

@keyframes morph-stations--pingpong--3-2 {
  0% {
    background-position-x: 100%;
  }
  20% {
    background-position-x: 100%;
  }
  30% {
    background-position-x: 50%;
  }
  70% {
    background-position-x: 50%;
  }
  80% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 0%;
  }
}
@keyframes morph-stations--pingpong--3-3 {
  0% {
    background-position-x: 100%;
  }
  70% {
    background-position-x: 100%;
  }
  80% {
    background-position-x: 50%;
  }
  100% {
    background-position-x: 50%;
  }
}

/* Character Set */

.anim-charset-glyph {
  animation: animation-charset-glyph 3s infinite ease-in-out alternate;
}

@keyframes animation-charset-glyph {
  0% {
    font-variation-settings: "wght" 300, "opsz" 32;
  }
  33% {
    font-variation-settings: "wght" 300, "opsz" 10;
  }
  66% {
    font-variation-settings: "wght" 900, "opsz" 10;
  }
  100% {
    font-variation-settings: "wght" 900, "opsz" 32;
  }
}

/* Area Divider */

.anim-divider-center {
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-name: animation-divider-center;
}

@keyframes animation-divider-center {
  0% {
    transform: rotate(0deg);
  }
  35% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(45deg);
  }
  60% {
    transform: rotate(45deg);
  }
  65% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

.anim-divider-outermost {
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-name: animation-divider-outermost;
}

@keyframes animation-divider-outermost {
  0% {
    flex-basis: 10%;
  }
  20% {
    flex-basis: 10%;
  }
  25% {
    flex-basis: 20%;
  }
  45% {
    flex-basis: 20%;
  }
  50% {
    flex-basis: 10%;
  }
  100% {
    flex-basis: 10%;
  }
}
